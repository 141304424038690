<template>
    <div class="w-full h-full flex items-center justify-center bg-lightgray">

        <div class="h-auto w-9/12 bg-lightgray rounded-lg shadow-shadowcard max-w-lg overflow-hidden">

            <div class="px-2 pt-2 mt-4 flex flex-row justify-center items-center relative">
                <i class="mdi mdi-arrow-left ml-4 text-red absolute top-0 left-0" @click="goback()"></i>
                <img class="mx-auto w-auto h-16" alt="Vue logo" src="/img/LOGOGRUPO.png">
            </div>

            <Request
                     model="Auth"
                     action="recoverypass"
                     :form="form"
                     @success="onSuccess"
                     @error="onError"
                     v-slot="{ request, loading }" class="w-full">

                <div
                     @keypress.enter="request"
                     class="mt-2 w-full py-4 rounded-lg flex flex-col justify-center items-center">

                    <label class="block w-10/12">
                        <input type="email" v-model="form.email" name="email" class="w-full mt-1 form-input py-1 border-0 text-gray font-semibold" :class="{'border border-red': error}" placeholder="Email">
                    </label>
                    
                    <div class="mt-4 w-5/12 shadow">
                        <button
                                @click="request"
                                class="w-full px-4 py-1 bg-red font-medium text-white rounded-lg"
                                :class="{ 'opacity-50': loading }"
                                :disabled="loading">
                            {{ $t('recovery') }}
                        </button>
                    </div>
                </div>

            </Request>
            <div class="w-full py-4 text-gray text-center text-sm" v-if="show">
                {{$t('recoveryText')}}
            </div>
            <div class="h-auto py-2 bg-darkgray2 flex flex-row justify-center items-center">
                <span class="text-2xs font-thing text-gray">Powered by Zeus</span>
            </div>

        </div>


    </div>
</template>

<script>
    import { Request } from '@/api/components';
    import { actions } from '@/store';

    export default {
        name: 'Login',
        components: {
            Request
        },
        data() {
            return {
                form:{
                    email: '',
                    url:window.location.origin
                },
                error: false,
                show:false,

            };
        },
        methods: {
            onSuccess(response) {
                
                this.show=true;
                //actions.setToken(response.data);
                //this.$router.push({name : '/group'});
            },
            onError(error) {
               console.log(error)

                if(error.error == 'passwords.user'){

                    this.$message.error('No existe el usuario');

                } else if(error.error == 'passwords.throttled'){

                    this.$message.error('Demasiados intentos de restablecimiento de contraseña');

                } else {

                    this.$message.error('En estos momentos estamos experimentando problemas');

                }

                this.error = true

            },
            goback(){
                this.$router.go(-1)
            },
        }
    }
</script>

